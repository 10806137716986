import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { BackgroundTexture } from '../components/background-texture';
import { ContactSection } from '../components/contact-section';
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { SideBySide } from '../components/side-by-side';
import { CTA } from '../components/cta';
import { InstagramWidget } from '../components/instagram-widget';

function AboutPage(): React.ReactElement {
  return (
    <>
      <SEO
        title="About"
        description="Adore Beauty and Cosmetics Tattoo is a boutique beauty salon based in Port Macquarie with over 18 years of experience within the beauty industry. Specialising in cosmetic brow tattoo, skin needling and facials, we are focused on delivering results beyond your expectations."
      />
      <Layout>
        <Hero />
        <AboutTheTeam />
        <OurStory />
        <CTA
          text="Want to know more about our cosmetic tattoo services?"
          path="/cosmetic-tattooing/"
        />
        <InstagramWidget />
        <ContactSection />
      </Layout>
    </>
  );
}

function Hero(): React.ReactElement {
  return (
    <div className="relative hidden max-h-screen sm:block">
      {/* <div className="absolute md:hidden -top-24">
        <StaticImage
          quality={90}
          src="../images/hero-bg.png"
          alt=""
          width={700}
          className="flex-1 w-full object-full"
        />
      </div> */}
      <div className="absolute inset-0 flex">
        <StaticImage
          quality={90}
          src="../images/about-hero.jpeg"
          alt=""
          className="flex-1 object-cover"
        />

        <div className="absolute inset-0 flex">
          <div className="flex-1 hero-bg-filter" />
        </div>
      </div>
      <div className="relative w-full px-4 py-24 mx-auto md:py-48 sm:px-6 lg:px-8 text-custom-gradient max-w-screen-2xl">
        <h1 className="hidden text-4xl font-semibold text-center sm:block md:text-left">
          Your Artist - Jess
        </h1>
      </div>
    </div>
  );
}

function AboutTheTeam() {
  return (
    <SideBySide background={<BackgroundTexture />}>
      <SideBySide.TwoCols>
        <div className="w-full mx-auto max-w-prose">
          <div className="max-w-xs mx-auto lg:max-w-prose lg:mx-0 md:pt-24">
            <div className="aspect-w-1 aspect-h-1">
              <div className="flex">
                <StaticImage
                  quality={90}
                  src="../images/owner.jpeg"
                  alt=""
                  className="flex-1 rounded-full safari-hack"
                />
              </div>
            </div>
          </div>
        </div>
      </SideBySide.TwoCols>
      <SideBySide.ThreeCols>
        <div className="relative mx-auto">
          <h2 className="text-3xl font-semibold text-center lg:text-left">
            <span className="block text-custom-gradient sm:hidden">
              Your Artist - Jess
            </span>
          </h2>
          <div className="mt-5 font-medium prose text-center text-gray-500 lg:text-left">
            <p>
              My journey started 18 years ago as a beauty therapist and makeup
              artist where I discovered my passion for providing my clients with
              beautiful results that made them feel more confident in the way
              they looked. Putting my full focus into the art of cosmetic
              tattoo, I have since dedicated years into training and developing
              my skills as a permanent makeup artist, an industry that has
              become very popular in recent years, offering clients the chance
              to have the brows and lips they’ve always wanted.
            </p>
          </div>
        </div>
      </SideBySide.ThreeCols>
    </SideBySide>
  );
}

function OurStory() {
  return (
    <SideBySide background={<BackgroundTexture position="left" />}>
      <SideBySide.ThreeCols>
        <div className="relative mx-auto">
          <div className="mt-5 font-medium prose text-center text-gray-500 lg:text-left">
            <p>
              Adore specialise in the art of cosmetic tattoo. As we know, we all
              have different wants and needs when it comes our features. With
              brows, some prefer bold and defined while others like to keep them
              as soft and natural as possible. The same applies to lips and
              eyeliner. Therefore, each cosmetic tattoo service is completely
              customised to the individual. For best results a full in-depth
              consultation is required. Including brow and lip mapping, shape
              correction, and colour selection to achieve best possible results.
            </p>
          </div>
        </div>
      </SideBySide.ThreeCols>
      <SideBySide.TwoCols>
        <div className="w-full mx-auto max-w-prose md:pb-24">
          <div className="max-w-xs mx-auto lg:max-w-prose lg:mx-0">
            <div className="aspect-w-1 aspect-h-1">
              <div className="flex">
                <StaticImage
                  quality={90}
                  src="../images/about-3.jpeg"
                  alt=""
                  className="flex-1 rounded-full safari-hack"
                />
              </div>
            </div>
          </div>
        </div>
      </SideBySide.TwoCols>
    </SideBySide>
  );
}

export default AboutPage;
